import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";
import BaseGrid from "~/components/GridLayout";
import NonFpPageContainer from "~/components/NonFpPageContainer";
import SEO from "~/components/SEO";

const Grid = styled(BaseGrid)`
  padding: 0;

  & > *:first-child {
    grid-column: 1 / span 4;
    grid-row: 1;
    & > *:first-child {
      margin-top: 0;
    }
  }
  & > *:nth-child(2) {
    grid-column: 6 / span 7;
    grid-row: 1;
    & > *:first-child {
      margin-top: 0;
    }
  }

  @media (max-width: 768px) {
    & > *:first-child,
    & > *:nth-child(2) {
      grid-column: 1 / span 12;
      & > *:first-child {
        margin-top: 2em;
      }
    }
    & > *:first-child {
      grid-row: 1;
    }
    & > *:nth-child(2) {
      grid-row: 2;
    }
  }
`;

const useAboutPageQuery = () =>
  useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: { slug: { eq: "imprint" } }) {
          html
          frontmatter {
            title
            contact {
              name
              street
              zipCode
              city
              mail
            }
          }
        }
      }
    `,
  );

interface ImprintPageTemplateProps {
  title: string;
  contact: {
    name: string;
    street: string;
    zipCode: string;
    city: string;
    mail: string;
  };
  bodyContent: string;
}

const ImprintPageTemplate = ({
  title,
  contact: { name, street, zipCode, city, mail },
  bodyContent,
}: ImprintPageTemplateProps) => {
  return (
    <NonFpPageContainer>
      <h1>{title}</h1>
      <Grid>
        <div>
          <h3>Angaben gemäß § 5 TMG</h3>
          <h5>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</h5>
          <p>{name}</p>
          <p>{street}</p>
          <p>{`${zipCode} ${city}`}</p>

          <h3>Kontakt</h3>
          <p>
            E-Mail: <a href={`mailto:${mail}`}>{mail}</a>
          </p>
        </div>
        <div dangerouslySetInnerHTML={{ __html: bodyContent }} />
      </Grid>
    </NonFpPageContainer>
  );
};

export default function ImprintPage() {
  const {
    markdownRemark: {
      html,
      frontmatter: { title, contact },
    },
  } = useAboutPageQuery();
  return (
    <>
      <SEO title="Imprint" />
      <ImprintPageTemplate bodyContent={html} title={title} contact={contact} />
    </>
  );
}
