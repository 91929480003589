import styled, { css } from "styled-components";

const NonFpPageContainer = styled.main`
  ${({ theme }) => css`
    ${theme.dimensions.mixins.centeredBody.static}
    margin-bottom: 3em;

    h1 {
      margin: 1em 0 2em 0;
    }
    h2 {
      margin: 2em 0;
    }
    p {
      margin: 0.2em 0;
      color: ${theme.palette.onBackground.shade1};
    }
  `}
`;

export default NonFpPageContainer;
